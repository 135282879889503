import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Long: any;
  MultiplierPath: any;
};

export type AddArticleInput = {
  articleNumber: Scalars['String'];
  createdBy: Scalars['String'];
  orderId: Scalars['String'];
  quantity: Scalars['Float'];
  storeId: Scalars['String'];
  dcNumber: Scalars['String'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type Article = {
  __typename?: 'Article';
  articleNumber: Scalars['String'];
  cartonCost: Scalars['Float'];
  category: Scalars['String'];
  countryOfOrigin: Scalars['String'];
  description: Scalars['String'];
  endOfSeason: Scalars['Boolean'];
  lastOrderQuantity: Scalars['Float'];
  newArticle: Scalars['Boolean'];
  orderMultiple: Scalars['Float'];
  plu: Scalars['String'];
  subCategory: Scalars['String'];
  temporarilyOutOfStock: Scalars['Boolean'];
  unitCost: Scalars['Float'];
  unitRrp: Scalars['Float'];
  uom: Scalars['String'];
};

export type ArticleMutationResponse = {
  __typename?: 'ArticleMutationResponse';
  hasErrors: Scalars['Boolean'];
  item: Maybe<MutationArticle>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ArticlesPagedResponse = PagedResponse & {
  __typename?: 'ArticlesPagedResponse';
  hasErrors: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  items: Array<Article>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  departmentId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CategoryItemsResponse = {
  __typename?: 'CategoryItemsResponse';
  hasErrors: Scalars['Boolean'];
  items: Maybe<Array<Maybe<Category>>>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CopyOrderDetail = {
  __typename?: 'CopyOrderDetail';
  articleCount: Maybe<Scalars['Int']>;
  articles: Maybe<Array<Maybe<OrderArticle>>>;
  deliveryDate: Scalars['String'];
  failedArticles: Maybe<Array<Maybe<OrderArticle>>>;
  grossProfitPercentage: Scalars['Float'];
  id: Scalars['Int'];
  numberOfCartons: Scalars['Long'];
  recommendedRetailPrice: Scalars['Float'];
  rosterId: Scalars['Long'];
  sourceOfSupplyId: Scalars['String'];
  sourceOfSupplyName: Scalars['String'];
  status: Scalars['String'];
  storeId: Scalars['String'];
  submittedDateTime: Maybe<Scalars['String']>;
  totalCost: Scalars['Float'];
};

export type CopyOrderInput = {
  createdBy: Scalars['String'];
  deliveryDateTimeUtc: Scalars['String'];
  isPendingOrder: Scalars['Boolean'];
  orderId: Scalars['String'];
  rosterCutOffDateTimeUtc: Scalars['String'];
  rosterId: Scalars['Int'];
  sourceOfSupplyId: Scalars['String'];
  sourceOfSupplyName: Scalars['String'];
  storeId: Scalars['String'];
};

export type CopyOrderMutationResponse = {
  __typename?: 'CopyOrderMutationResponse';
  hasErrors: Scalars['Boolean'];
  item: Maybe<CopyOrderDetail>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationStoreForecastServiceResponse = {
  __typename?: 'MutationStoreForecastServiceResponse';
  hasErrors: Scalars['Boolean'];
  item: Scalars['Boolean']; //Maybe<MutationStoreForecast>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationStoreForecast = {
  __typename?: 'MutationStoreForecast';
  storeId: Scalars['String'];
  pluCode: Scalars['String'];
  forecastDate: Scalars['String'];
  isSuccessful: Scalars['Boolean'];
}

export type CreateOrderInput = {
  createdBy: Scalars['String'];
  deliveryDateTimeUtc: Scalars['String'];
  rosterCutOffDateTimeUtc: Scalars['String'];
  rosterId: Scalars['Int'];
  sourceOfSupplyId: Scalars['String'];
  sourceOfSupplyName: Scalars['String'];
  storeId: Scalars['String'];
};

export type DcForecast = {
  __typename?: 'DcForecast';
  articleNumber: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  financialWeekEndDate: Scalars['DateTime'];
  financialWeekStartDate: Scalars['DateTime'];
  forecast: Array<Maybe<Forecast>>;
  id: Scalars['String'];
  subCategory: Maybe<Scalars['String']>;
  uom: Maybe<Scalars['String']>;
  totalSales: Maybe<Scalars['String']>;
  pluCode: Scalars['String'];
};

export type DcForecastResponse = PagedResponse & {
  __typename?: 'DcForecastResponse';
  hasErrors: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  items: Maybe<Array<Maybe<DcForecast>>>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount: Scalars['Int'];
};


export type Forecast = {
  __typename?: 'Forecast';
  date: Scalars['DateTime'];
  enteredQty: Scalars['String'];
  quantity: Scalars['String'];
};


export type Mutation = {
  __typename?: 'Mutation';
  addArticleToOrder: Maybe<ArticleMutationResponse>;
  copyOrder: Maybe<CopyOrderMutationResponse>;
  createOrder: Maybe<OrderMutationResponse>;
  deleteArticleInOrder: Maybe<ArticleMutationResponse>;
  deleteArticles: Maybe<ArticleMutationResponse>;
  deleteOrder: Maybe<OrderMutationResponse>;
  submitOrder: Maybe<OrderMutationResponse>;
  updateArticleInOrder: Maybe<ArticleMutationResponse>;
  updateDcForecast: Maybe<MutationDcForecastServiceResponse>;
  updateOrder: Maybe<OrderMutationResponse>;
  updateStoreForecast: Maybe<MutationStoreForecastServiceResponse>;
};

export type StoreForecast = {
  __typename?: 'StoreForecast';
  id?: Scalars['String'];
  storeId: Scalars['String'];
  pluCode?: Maybe<Scalars['String']>;
  financialWeekStartDate?: Maybe<Scalars['String']>;
  financialWeekEndDate?: Maybe<Scalars['String']>;
  forecast: Maybe<ForecastQuantity[]>;
  sale?: Maybe<ForecastQuantity[]>;
  description?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  plus?: Maybe<Scalars['String']>;
  articleNumber?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>
};


export type CalculatedDates = {
  __typename?: 'CalculatedDates';
  startDate: Date,
  endDate: Date,
  saleStartDate: Date,
  saleEndDate: Date,
  preEnabled: boolean,
  nextEnabled: boolean
};


export type ForecastQuantity = {
  __typename?: 'ForecastQuantity';
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  enteredQty?: Scalars['String'];
  sale?:  Maybe<Scalars['String']>;
}


export type MutationAddArticleToOrderArgs = {
  newArticle: AddArticleInput;
};


export type MutationCopyOrderArgs = {
  copyOrder: CopyOrderInput;
};


export type MutationCreateOrderArgs = {
  newOrder: CreateOrderInput;
};


export type MutationDeleteArticleInOrderArgs = {
  articleNumber: Scalars['String'];
  orderId: Scalars['String'];
  storeId: Scalars['String'];
};


export type MutationDeleteArticlesArgs = {
  articleNumbers: Maybe<Array<Maybe<Scalars['String']>>>;
  orderId: Maybe<Scalars['String']>;
};


export type MutationDeleteOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationSubmitOrderArgs = {
  orderId: Scalars['String'];
  storeId: Scalars['String'];
  submittedBy: Scalars['String'];
};


export type MutationUpdateArticleInOrderArgs = {
  article: UpdateArticleInput;
};


export type MutationUpdateDcForecastArgs = {
  forecast: Maybe<UpdateDcForecastInput>;
};


export type MutationUpdateOrderArgs = {
  orderToUpdate: UpdateOrderInput;
};


export type MutationUpdateStoreForecastArgs = {
  forecast: Maybe<UpdateStoreForecastInput>;
};

export type MutationArticle = {
  __typename?: 'MutationArticle';
  articleNumber: Maybe<Scalars['String']>;
};

export type MutationDcForecastServiceResponse = {
  __typename?: 'MutationDcForecastServiceResponse';
  hasErrors: Scalars['Boolean'];
  item: Scalars['Boolean'];
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderArticle = {
  __typename?: 'OrderArticle';
  acknowledgedOrderMultiple: Scalars['Float'];
  acknowledgedQuantity: Maybe<Scalars['Float']>;
  acknowledgedUnits: Maybe<Scalars['Float']>;
  articleNumber: Scalars['String'];
  cartonCost: Scalars['Float'];
  countryOfOrigin: Scalars['String'];
  description: Scalars['String'];
  endOfSeason: Scalars['Boolean'];
  invoiceReferenceNumber: Maybe<Scalars['String']>;
  invoicedOrderMultiple: Scalars['Float'];
  invoicedQuantity: Maybe<Scalars['Float']>;
  lineStatuses: Maybe<Array<Maybe<OrderArticleStatus>>>;
  newArticle: Scalars['Boolean'];
  orderMultiple: Scalars['Float'];
  requestedQuantity: Scalars['Float'];
  stockOnOrder: Scalars['Float'];
  temporarilyOutOfStock: Scalars['Boolean'];
  unitCost: Scalars['Float'];
  unitRrp: Scalars['Float'];
  uom: Scalars['String'];
};

export type OrderArticleStatus = {
  __typename?: 'OrderArticleStatus';
  code: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  event: Maybe<Scalars['String']>;
};

export type OrderDetail = {
  __typename?: 'OrderDetail';
  articleCount: Maybe<Scalars['Int']>;
  articles: Maybe<Array<Maybe<OrderArticle>>>;
  deliveryDate: Scalars['String'];
  grossProfitPercentage: Scalars['Float'];
  id: Scalars['Int'];
  isPendingOrder: Scalars['Boolean'];
  numberOfCartons: Scalars['Long'];
  purchaseOrderNumber: Maybe<Scalars['String']>;
  recommendedRetailPrice: Scalars['Float'];
  rosterId: Scalars['Long'];
  sourceOfSupplyId: Scalars['String'];
  sourceOfSupplyName: Maybe<Scalars['String']>;
  sourceReferenceId: Maybe<Scalars['String']>;
  status: Scalars['String'];
  storeId: Scalars['String'];
  submittedDateTime: Maybe<Scalars['String']>;
  totalCost: Scalars['Float'];
};

export type OrderItemResponse = {
  __typename?: 'OrderItemResponse';
  hasErrors: Scalars['Boolean'];
  item: Maybe<OrderDetail>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderMutationResponse = {
  __typename?: 'OrderMutationResponse';
  hasErrors: Scalars['Boolean'];
  item: Maybe<OrderDetail>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderPagedResponse = PagedResponse & {
  __typename?: 'OrderPagedResponse';
  hasErrors: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  items: Maybe<Array<Maybe<OrderSummary>>>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount: Scalars['Int'];
};

export type OrderSummary = {
  __typename?: 'OrderSummary';
  articleCount: Maybe<Scalars['Int']>;
  deliveryDate: Scalars['String'];
  id: Scalars['Int'];
  isPendingOrder: Scalars['Boolean'];
  purchaseOrderNumber: Maybe<Scalars['String']>;
  sourceOfSupplyId: Scalars['String'];
  sourceOfSupplyName: Maybe<Scalars['String']>;
  sourceReferenceId: Maybe<Scalars['String']>;
  status: StatusType;
  submittedDateTime: Maybe<Scalars['String']>;
};

export type PagedResponse = {
  hasErrors: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  articles: Maybe<ArticlesPagedResponse>;
  articlesBySubCategory: Maybe<ArticlesPagedResponse>;
  categories: Maybe<CategoryItemsResponse>;
  currentOrderByStore: Maybe<OrderItemResponse>;
  dcForecast: Maybe<DcForecastResponse>;
  ordersByStore: Maybe<OrderPagedResponse>;
  report: Maybe<ReportItemResponse>;
  retrieveOrder: Maybe<OrderItemResponse>;
  siteBySiteNumber: Maybe<SiteItemResponse>;
  sites: Maybe<SitePagedResponse>;
  sourceOfSupplyBySourceOfSupplyNumber: Maybe<SourceOfSupplyItemResponse>;
  sourceOfSupplyList: Maybe<SourceOfSupplyPagedItemResponse>;
  storeOrderRosters: Maybe<StoreOrderRostersPagedResponse>;
  storeOrderRostersBySite: Maybe<StoreOrderRostersPagedResponse>;
  storeRetailForecast: Maybe<StoreRetailForecastResponse>;
  subCategoriesWithFilters: Maybe<SubCategoryItemsResponse>;
};


export type QueryArticlesArgs = {
  limit: Maybe<Scalars['Int']>;
  locationNumber: Maybe<Scalars['String']>;
  offset: Maybe<Scalars['Int']>;
};


export type QueryArticlesBySubCategoryArgs = {
  limit: Maybe<Scalars['Int']>;
  locationNumber: Scalars['String'];
  offset: Maybe<Scalars['Int']>;
  subCategoryHierarchyNode: Scalars['String'];
};


export type QueryCurrentOrderByStoreArgs = {
  storeId: Scalars['String'];
};


export type QueryDcForecastArgs = {
  dc: Maybe<Scalars['String']>;
  from: Maybe<Scalars['DateTime']>;
  to: Maybe<Scalars['DateTime']>;
};


export type QueryOrdersByStoreArgs = {
  deliveryDateUtc: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  orderStatus: Maybe<Scalars['String']>;
  storeId: Scalars['String'];
  submittedDateUtc: Maybe<Scalars['String']>;
};


export type QueryReportArgs = {
  reportParams: Maybe<ReportParams>;
};


export type QueryRetrieveOrderArgs = {
  isPendingOrder: Scalars['Boolean'];
  orderId: Scalars['String'];
  storeId: Scalars['String'];
};


export type QuerySiteBySiteNumberArgs = {
  siteNumber: Scalars['String'];
};


export type QuerySitesArgs = {
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type QuerySourceOfSupplyBySourceOfSupplyNumberArgs = {
  sourceOfSupplyNumber: Maybe<Scalars['String']>;
};


export type QueryStoreOrderRostersArgs = {
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type QueryStoreOrderRostersBySiteArgs = {
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  siteNumber: Scalars['String'];
};


export type QueryStoreRetailForecastArgs = {
  dc: Maybe<Scalars['String']>;
  from: Maybe<Scalars['DateTime']>;
  storeId: Maybe<Scalars['String']>;
  to: Maybe<Scalars['DateTime']>;
};


export type QuerySubCategoriesWithFiltersArgs = {
  categoryHierarchyNode: Maybe<Scalars['String']>;
  departmentHierarchyNode: Maybe<Scalars['String']>;
  storeId: Maybe<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  fileContent: Scalars['String'];
};

export enum ReportArticlesSelectionType {
  All = 'all',
  ByCategory = 'byCategory',
  LatestOrder = 'latestOrder'
}

export type ReportItemResponse = {
  __typename?: 'ReportItemResponse';
  hasErrors: Scalars['Boolean'];
  item: Maybe<Report>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportParams = {
  articlesType: Maybe<ReportArticlesSelectionType>;
  categoryIds: Maybe<Array<Maybe<Scalars['String']>>>;
  isPendingOrder: Scalars['Boolean'];
  orderId: Maybe<Scalars['String']>;
  reportType: Maybe<ReportType>;
  storeId: Scalars['String'];
  storeName: Scalars['String'];
};

export enum ReportType {
  Daily = 'daily',
  Weekly = 'weekly'
}

export type SalesForecast = {
  __typename?: 'SalesForecast';
  date: Scalars['DateTime'];
  enteredQty: Scalars['String'];
  quantity: Scalars['String'];
  sale: Scalars['String'];
};

export type Site = {
  __typename?: 'Site';
  divisionNumber: Scalars['Int'];
  siteName: Scalars['String'];
  siteNumber: Scalars['String'];
  siteType: Scalars['String'];
};

export type SiteItemResponse = {
  __typename?: 'SiteItemResponse';
  hasErrors: Scalars['Boolean'];
  item: Maybe<Site>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SitePagedResponse = PagedResponse & {
  __typename?: 'SitePagedResponse';
  hasErrors: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  items: Maybe<Array<Maybe<Site>>>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount: Scalars['Int'];
};

export type SourceOfSupply = {
  __typename?: 'SourceOfSupply';
  sourceOfSupplyName: Scalars['String'];
  sourceOfSupplyNumber: Scalars['String'];
  sourceOfSupplyType: Scalars['String'];
};

export type SourceOfSupplyItemResponse = {
  __typename?: 'SourceOfSupplyItemResponse';
  hasErrors: Scalars['Boolean'];
  item: Maybe<SourceOfSupply>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SourceOfSupplyPagedItemResponse = PagedResponse & {
  __typename?: 'SourceOfSupplyPagedItemResponse';
  hasErrors: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  items: Array<SourceOfSupply>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount: Scalars['Int'];
};

export enum StatusType {
  Acknowledged = 'Acknowledged',
  Closed = 'Closed',
  Created = 'Created',
  InProgress = 'InProgress',
  Invoiced = 'Invoiced',
  PendingSubmission = 'PendingSubmission',
  Submitted = 'Submitted'
}

export type StoreOrderRoster = {
  __typename?: 'StoreOrderRoster';
  cutOffDateTimeUtc: Scalars['String'];
  deliveryDateTimeUtc: Scalars['String'];
  dispatchDateTimeUtc: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  processDateTimeUtc: Scalars['String'];
  rosterDateTimeUtc: Scalars['String'];
  scheduleId: Scalars['Int'];
  scheduleName: Maybe<Scalars['String']>;
  siteNumber: Scalars['String'];
  sourceOfSupplyName: Maybe<Scalars['String']>;
  sourceOfSupplyNumber: Scalars['String'];
};

export type StoreOrderRostersPagedResponse = PagedResponse & {
  __typename?: 'StoreOrderRostersPagedResponse';
  hasErrors: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  items: Array<StoreOrderRoster>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount: Scalars['Int'];
};

export type StoreRetailForecast = {
  __typename?: 'StoreRetailForecast';
  article: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  financialWeekEndDate: Scalars['DateTime'];
  financialWeekStartDate: Scalars['DateTime'];
  forecast: Array<Maybe<SalesForecast>>;
  id: Scalars['String'];
  pluCode: Maybe<Scalars['String']>;
  storeId: Scalars['Int'];
  subCategory: Maybe<Scalars['String']>;
  uom: Maybe<Scalars['String']>;
};

export type StoreRetailForecastResponse = PagedResponse & {
  __typename?: 'StoreRetailForecastResponse';
  hasErrors: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  itemCount: Scalars['Int'];
  items: Maybe<Array<Maybe<StoreRetailForecast>>>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount: Scalars['Int'];
};

export type SubCategory = {
  __typename?: 'SubCategory';
  category: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  numberOfArticles: Scalars['Int'];
};

export type SubCategoryItemsResponse = {
  __typename?: 'SubCategoryItemsResponse';
  hasErrors: Scalars['Boolean'];
  items: Maybe<Array<Maybe<SubCategory>>>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateArticleInput = {
  articleNumber: Scalars['String'];
  modifiedBy: Scalars['String'];
  orderId: Scalars['String'];
  quantity: Scalars['Float'];
  storeId: Scalars['String'];
};

export type UpdateDcForecastInput = {
  dc: Scalars['String'];
  forecastDate: Scalars['String'];
  forecastEnteredQty: Scalars['String'];
  articleNumber: Scalars['String'];
  financialWeekStartDate: Scalars['String'];
};

export type UpdateOrderInput = {
  deliveryDateTimeUtc: Scalars['String'];
  modifiedBy: Scalars['String'];
  orderId: Scalars['String'];
  rosterCutOffDateTimeUtc: Scalars['String'];
  rosterId: Scalars['Int'];
  sourceOfSupplyId: Scalars['String'];
  sourceOfSupplyName: Scalars['String'];
};

export type UpdateStoreForecastInput = {
  storeId: Scalars['String'];
  pluCode: Scalars['String'];
  forecastDate: Scalars['String'];
    forecastEnteredQty: Scalars['String'];
};

export type AddArticleMutationVariables = Exact<{
  newArticle: AddArticleInput;
}>;


export type AddArticleMutation = { __typename?: 'Mutation', addArticleToOrder: { __typename?: 'ArticleMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'MutationArticle', articleNumber: string | null } | null } | null };

export type GetAllArticlesQueryVariables = Exact<{
  locationNumber: Scalars['String'];
  dc: Scalars['String'];
  offset: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
}>;

export type GetAllForecastQuery = { __typename?: 'Query', storeRetailForecast: { __typename?: 'StoreRetailForecastResponse', hasNextPage: boolean, hasPreviousPage: boolean, totalCount: number, hasErrors: boolean, messages: Array<string | null> | null, itemCount: number, items: Array<{ __typename?: 'StoreForecast', id:string, storeId: string, pluCode: string, category: string,subCategory: string,description: string, financialWeekStartDate: string, financialWeekEndDate: string, articleNumber: string, forecast: Array<{ __typename?: 'ForecastQuantity', date: string, quantity: string, enteredQty: string, sale: string }>}> } | null };
export type GetDcForecastQuery = { __typename?: 'Query', dcForecast: { __typename?: 'DcForecastResponse', hasNextPage: boolean, hasPreviousPage: boolean, totalCount: number, hasErrors: boolean, messages: Array<string | null> | null, itemCount: number, items: Array<{ __typename?: 'DcForecast', id:string, uom: string, totalSales:string, pluCode: string, articleNumber: string,subCategory: string,category: string, financialWeekStartDate: string, financialWeekEndDate: string, description: string, forecast: Array<{ __typename?: 'ForecastQuantity', date: string, quantity: string, enteredQty: string, sale: string }>}> } | null };


export type GetAllForecastQueryVariables = Exact<{
  from: Scalars['String'];
  to:  Scalars['String'];
  dc:  Scalars['String'];
  storeId: Scalars['String'];
}>;

export type GetDcForecastQueryVariables = Exact<{
  from: Scalars['String'];
  to:  Scalars['String'];
  dc:  Scalars['String'];
}>;

export type GetDCQuery = { __typename?: 'Query', sourceOfSupplyList: { __typename?: 'SourceOfSupplyPagedItemResponse', hasNextPage: boolean, hasPreviousPage: boolean, totalCount: number, hasErrors: boolean, messages: Array<string | null> | null, itemCount: number, items: Array<{ __typename?: 'SourceOfSupply', sourceOfSupplyNumber:string, sourceOfSupplyName: string, sourceOfSupplyType: string}> } | null };


export type GetDCQueryVariables = Exact<{ [key: string]: never; }>;

export type GetAllArticlesQuery = { __typename?: 'Query', articles: { __typename?: 'ArticlesPagedResponse', hasNextPage: boolean, hasPreviousPage: boolean, totalCount: number, hasErrors: boolean, messages: Array<string | null> | null, itemCount: number, items: Array<{ __typename?: 'Article', articleNumber: string, description: string, subCategory: string, category: string, countryOfOrigin: string, uom: string, plu: string, orderMultiple: number, cartonCost: number, unitCost: number, unitRrp: number, lastOrderQuantity: number, temporarilyOutOfStock: boolean, endOfSeason: boolean, newArticle: boolean }> } | null };

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCategoriesQuery = { __typename?: 'Query', categories: { __typename?: 'CategoryItemsResponse', items: Array<{ __typename?: 'Category', name: string, id: string, departmentId: string } | null> | null } | null };

export type GetArticlesBySubCategoryQueryVariables = Exact<{
  locationNumber: Scalars['String'];
  dc: Scalars['String'];
  subCategoryHierarchyNode: Scalars['String'];
  offset: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
}>;


export type GetArticlesBySubCategoryQuery = { __typename?: 'Query', articlesBySubCategory: { __typename?: 'ArticlesPagedResponse', items: Array<{ __typename?: 'Article', articleNumber: string, description: string, subCategory: string, category: string, countryOfOrigin: string, uom: string, plu: string, orderMultiple: number, cartonCost: number, unitCost: number, unitRrp: number, lastOrderQuantity: number, temporarilyOutOfStock: boolean, endOfSeason: boolean, newArticle: boolean }> } | null };

export type CopyOrderMutationVariables = Exact<{
  orderInput: CopyOrderInput;
}>;


export type CopyOrderMutation = { __typename?: 'Mutation', copyOrder: { __typename?: 'CopyOrderMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'CopyOrderDetail', id: number, storeId: string, status: string, rosterId: any, deliveryDate: string, articleCount: number | null, numberOfCartons: any, grossProfitPercentage: number, recommendedRetailPrice: number, totalCost: number, sourceOfSupplyName: string, failedArticles: Array<{ __typename?: 'OrderArticle', articleNumber: string, description: string } | null> | null, articles: Array<{ __typename?: 'OrderArticle', articleNumber: string, requestedQuantity: number, acknowledgedQuantity: number | null, invoicedQuantity: number | null, invoiceReferenceNumber: string | null, countryOfOrigin: string } | null> | null } | null } | null };

export type CreateOrderMutationVariables = Exact<{
  orderInput: CreateOrderInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'OrderMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'OrderDetail', id: number, isPendingOrder: boolean, storeId: string, status: string, rosterId: any, deliveryDate: string, articleCount: number | null, numberOfCartons: any, grossProfitPercentage: number, recommendedRetailPrice: number, totalCost: number, sourceOfSupplyName: string | null, sourceOfSupplyId: string, articles: Array<{ __typename?: 'OrderArticle', articleNumber: string, description: string, requestedQuantity: number, acknowledgedQuantity: number | null, invoicedQuantity: number | null, invoiceReferenceNumber: string | null, uom: string, orderMultiple: number, unitCost: number, unitRrp: number, cartonCost: number } | null> | null } | null } | null };

export type GetCurrentOrderByStoreQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetCurrentOrderByStoreQuery = { __typename?: 'Query', currentOrderByStore: { __typename?: 'OrderItemResponse', item: { __typename?: 'OrderDetail', id: number, isPendingOrder: boolean, storeId: string, status: string, rosterId: any, deliveryDate: string, articleCount: number | null, numberOfCartons: any, grossProfitPercentage: number, recommendedRetailPrice: number, totalCost: number, sourceOfSupplyName: string | null, sourceOfSupplyId: string, articles: Array<{ __typename?: 'OrderArticle', articleNumber: string, description: string, requestedQuantity: number, acknowledgedUnits: number | null, acknowledgedQuantity: number | null, invoicedQuantity: number | null, invoiceReferenceNumber: string | null, uom: string, orderMultiple: number, unitCost: number, unitRrp: number, cartonCost: number, countryOfOrigin: string } | null> | null } | null } | null };

export type DeleteArticleMutationVariables = Exact<{
  orderId: Scalars['String'];
  articleNumber: Scalars['String'];
  storeId: Scalars['String'];
}>;


export type DeleteArticleMutation = { __typename?: 'Mutation', deleteArticleInOrder: { __typename?: 'ArticleMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'MutationArticle', articleNumber: string | null } | null } | null };

export type DeleteArticlesMutationVariables = Exact<{
  orderId: Maybe<Scalars['String']>;
  articleNumbers: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  storeId: Maybe<Scalars>['String'];
}>;


export type DeleteArticlesMutation = { __typename?: 'Mutation', deleteArticles: { __typename?: 'ArticleMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'MutationArticle', articleNumber: string | null } | null } | null };

export type DeleteOrderMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type DeleteOrderMutation = { __typename?: 'Mutation', deleteOrder: { __typename?: 'OrderMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'OrderDetail', id: number, storeId: string, rosterId: any } | null } | null };

export type GetOrderByOrderIdQueryVariables = Exact<{
  orderId: Scalars['String'];
  storeId: Scalars['String'];
  isPendingOrder: Scalars['Boolean'];
}>;


export type GetOrderByOrderIdQuery = { __typename?: 'Query', retrieveOrder: { __typename?: 'OrderItemResponse', item: { __typename?: 'OrderDetail', status: string, id: number, isPendingOrder: boolean, storeId: string, rosterId: any, deliveryDate: string, numberOfCartons: any, grossProfitPercentage: number, recommendedRetailPrice: number, totalCost: number, sourceOfSupplyName: string | null, sourceOfSupplyId: string, articles: Array<{ __typename?: 'OrderArticle', articleNumber: string, description: string, requestedQuantity: number, orderMultiple: number, acknowledgedUnits: number | null, acknowledgedQuantity: number | null, acknowledgedOrderMultiple: number, invoicedQuantity: number | null, invoicedOrderMultiple: number, invoiceReferenceNumber: string | null, uom: string, unitCost: number, unitRrp: number, cartonCost: number, temporarilyOutOfStock: boolean, endOfSeason: boolean, newArticle: boolean, countryOfOrigin: string, lineStatuses: Array<{ __typename?: 'OrderArticleStatus', description: string | null } | null> | null } | null> | null } | null } | null };

export type GetordersByStoreQueryVariables = Exact<{
  storeId: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  submittedDateUtc: Maybe<Scalars['String']>;
  deliveryDateUtc: Maybe<Scalars['String']>;
  orderStatus: Maybe<Scalars['String']>;
}>;


export type GetordersByStoreQuery = { __typename?: 'Query', ordersByStore: { __typename?: 'OrderPagedResponse', itemCount: number, hasNextPage: boolean, hasPreviousPage: boolean, totalCount: number, hasErrors: boolean, messages: Array<string | null> | null, items: Array<{ __typename?: 'OrderSummary', id: number, isPendingOrder: boolean, status: StatusType, sourceOfSupplyName: string | null, deliveryDate: string, articleCount: number | null, submittedDateTime: string | null } | null> | null } | null };

export type GetReportQueryVariables = Exact<{
  reportParams: Maybe<ReportParams>;
}>;


export type GetReportQuery = { __typename?: 'Query', report: { __typename?: 'ReportItemResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'Report', fileContent: string } | null } | null };

export type GetRosterQueryVariables = Exact<{
  offset: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
  siteNumber: Scalars['String'];
}>;


export type GetRosterQuery = { __typename?: 'Query', storeOrderRostersBySite: { __typename?: 'StoreOrderRostersPagedResponse', totalCount: number, hasNextPage: boolean, hasPreviousPage: boolean, items: Array<{ __typename?: 'StoreOrderRoster', id: number, scheduleId: number, scheduleName: string | null, processDateTimeUtc: string, cutOffDateTimeUtc: string, dispatchDateTimeUtc: string, rosterDateTimeUtc: string, deliveryDateTimeUtc: string, siteNumber: string, isActive: boolean, sourceOfSupplyName: string | null, sourceOfSupplyNumber: string }> } | null };

export type GetStoresQueryVariables = Exact<{
  offset: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
}>;


export type GetStoresQuery = { __typename?: 'Query', sites: { __typename?: 'SitePagedResponse', items: Array<{ __typename?: 'Site', siteNumber: string, siteName: string, siteType: string, divisionNumber: number } | null> | null } | null };

export type GetSubCategoriesQueryVariables = Exact<{
  departmentHierarchyNode: Maybe<Scalars['String']>;
  categoryHierarchyNode: Maybe<Scalars['String']>;
  storeId: Maybe<Scalars['String']>;
  dc: Maybe<Scalars['String']>;
}>;


export type GetSubCategoriesQuery = { __typename?: 'Query', subCategoriesWithFilters: { __typename?: 'SubCategoryItemsResponse', items: Array<{ __typename?: 'SubCategory', name: string, id: string, category: string, numberOfArticles: number } | null> | null } | null };

export type SubmitOrderMutationVariables = Exact<{
  orderId: Scalars['String'];
  submittedBy: Scalars['String'];
  storeId: Scalars['String'];
}>;


export type SubmitOrderMutation = { __typename?: 'Mutation', submitOrder: { __typename?: 'OrderMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'OrderDetail', id: number, submittedDateTime: string | null, articleCount: number | null, sourceOfSupplyId: string, status: string } | null } | null };

export type UpdateArticleMutationVariables = Exact<{
  article: UpdateArticleInput;
}>;


export type UpdateArticleMutation = { __typename?: 'Mutation', updateArticleInOrder: { __typename?: 'ArticleMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'MutationArticle', articleNumber: string | null } | null } | null };

export type UpdateOrderMutationVariables = Exact<{
  orderToUpdate: UpdateOrderInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder: { __typename?: 'OrderMutationResponse', hasErrors: boolean, messages: Array<string | null> | null, item: { __typename?: 'OrderDetail', id: number, storeId: string, rosterId: any } | null } | null };


export const AddArticleDocument = gql`
    mutation addArticle($newArticle: AddArticleInput!) {
  addArticleToOrder(newArticle: $newArticle) {
    item {
      articleNumber
    }
    hasErrors
    messages
  }
}
    `;

export type UpdateStoreForecastMutationVariables = Exact<{
    forecast: UpdateStoreForecastInput;
  }>;

export type UpdateStoreForecastMutation = { __typename?: 'Mutation', updateStoreForecast: { __typename?: 'MutationStoreForecastServiceResponse', hasErrors: boolean, messages: Array<string | null> | null, item: boolean }};


export const UpdateForecastDocument = gql`
      mutation updateStoreForecast($forecast: UpdateStoreForecastInput!) {
        updateStoreForecast(forecast: $forecast) {
      hasErrors
      messages
    }
  }
      `;


export type UpdateDcForecastMutationVariables = Exact<{
  forecast: UpdateDcForecastInput;
}>;

export type UpdateDcForecastMutation = { __typename?: 'Mutation', updateDcForecast: { __typename?: 'MutationDcForecastServiceResponse', hasErrors: boolean, messages: Array<string | null> | null, item: boolean }};


export const updateDcForecastDocument = gql`
    mutation updateDcForecast($forecast: UpdateDcForecastInput!) {
      updateDcForecast(forecast: $forecast) {
    hasErrors
    messages
  }
}
    `;


export type AddArticleMutationFn = Apollo.MutationFunction<AddArticleMutation, AddArticleMutationVariables>;

/**
 * __useAddArticleMutation__
 *
 * To run a mutation, you first call `useAddArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArticleMutation, { data, loading, error }] = useAddArticleMutation({
 *   variables: {
 *      newArticle: // value for 'newArticle'
 *   },
 * });
 */
export function useAddArticleMutation(baseOptions?: Apollo.MutationHookOptions<AddArticleMutation, AddArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddArticleMutation, AddArticleMutationVariables>(AddArticleDocument, options);
      }
export type AddArticleMutationHookResult = ReturnType<typeof useAddArticleMutation>;
export type AddArticleMutationResult = Apollo.MutationResult<AddArticleMutation>;
export type AddArticleMutationOptions = Apollo.BaseMutationOptions<AddArticleMutation, AddArticleMutationVariables>;
export const GetAllArticlesDocument = gql`
    query GetAllArticles($locationNumber: String!, $dc: String!, $offset: Int, $limit: Int) {
  articles(locationNumber: $locationNumber, dc: $dc, offset: $offset, limit: $limit) {
    items {
      articleNumber
      description
      subCategory
      category
      countryOfOrigin
      uom
      plu
      orderMultiple
      cartonCost
      unitCost
      unitRrp
      lastOrderQuantity
      temporarilyOutOfStock
      endOfSeason
      newArticle
    }
    hasNextPage
    hasPreviousPage
    totalCount
    hasErrors
    messages
    itemCount
  }
}
    `;

/**
 * __useGetAllArticlesQuery__
 *
 * To run a query within a React component, call `useGetAllArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArticlesQuery({
 *   variables: {
 *      locationNumber: // value for 'locationNumber'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllArticlesQuery(baseOptions: Apollo.QueryHookOptions<GetAllArticlesQuery, GetAllArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllArticlesQuery, GetAllArticlesQueryVariables>(GetAllArticlesDocument, options);
      }
export function useGetAllArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllArticlesQuery, GetAllArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllArticlesQuery, GetAllArticlesQueryVariables>(GetAllArticlesDocument, options);
        }
export type GetAllArticlesQueryHookResult = ReturnType<typeof useGetAllArticlesQuery>;
export type GetAllArticlesLazyQueryHookResult = ReturnType<typeof useGetAllArticlesLazyQuery>;
export type GetAllArticlesQueryResult = Apollo.QueryResult<GetAllArticlesQuery, GetAllArticlesQueryVariables>;
export const GetAllCategoriesDocument = gql`
    query GetAllCategories {
  categories {
    items {
      name
      id
      departmentId
    }
  }
}
    `;

    export function useGetAllForecastQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllForecastQuery, GetAllForecastQueryVariables>) {
      const options = {...defaultOptions, ...baseOptions}
      return Apollo.useLazyQuery<GetAllForecastQuery, GetAllForecastQueryVariables>(GetAllForecastDocument, options);
    }

    export const GetAllForecastDocument = gql`
    query storeRetailForecast($from: DateTime!,$to: DateTime!,$dc: String!, $storeId: String!) {
      storeRetailForecast(from: $from, to: $to, dc: $dc, storeId: $storeId) {
    items {
      id
      storeId
      pluCode
      description
      uom
      articleNumber
      category
      subCategory
      financialWeekStartDate
      financialWeekEndDate
      forecast {
        date
        quantity
        enteredQty
        sale
      }
    }
    hasNextPage
    hasPreviousPage
    totalCount
    hasErrors
    messages
    itemCount
  }
}
    `;


    export function useGetDcForecastQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDcForecastQuery, GetDcForecastQueryVariables>) {
      const options = {...defaultOptions, ...baseOptions}
      return Apollo.useLazyQuery<GetDcForecastQuery, GetDcForecastQueryVariables>(GetDcForecastDocument, options);
    }

    export const GetDcForecastDocument = gql`
    query dcForecast($from: DateTime!,$to: DateTime!,$dc: String!) {
      dcForecast(from: $from, to: $to, dc: $dc) {
    items {
      description
      id
      uom
      pluCode
      articleNumber
      category
      subCategory
      financialWeekStartDate
      financialWeekEndDate
      totalSales
      forecast {
        date
        quantity
        enteredQty
      }
    }
    hasNextPage
    hasPreviousPage
    totalCount
    hasErrors
    messages
    itemCount
  }
}
    `;


    export function useGetSourceOfSupplierQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDCQuery, GetDCQueryVariables>) {
      const options = {...defaultOptions, ...baseOptions}
      return Apollo.useLazyQuery<GetDCQuery, GetDCQueryVariables>(GetSourceOfSupplierDocument, options);
    }

     export const GetSourceOfSupplierDocument = gql`
    query {
      sourceOfSupplyList {
        items{
          sourceOfSupplyName
          sourceOfSupplyNumber
        }
  }
}
    `;


/**
 * __useGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
      }
export function useGetAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
        }
export type GetAllCategoriesQueryHookResult = ReturnType<typeof useGetAllCategoriesQuery>;
export type GetAllCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllCategoriesLazyQuery>;
export type GetAllCategoriesQueryResult = Apollo.QueryResult<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
export const GetArticlesBySubCategoryDocument = gql`
    query GetArticlesBySubCategory($locationNumber: String!, $dc: String!, $subCategoryHierarchyNode: String!, $offset: Int, $limit: Int) {
  articlesBySubCategory(
    locationNumber: $locationNumber
    dc: $dc
    subCategoryHierarchyNode: $subCategoryHierarchyNode
    offset: $offset
    limit: $limit
  ) {
    items {
      articleNumber
      description
      subCategory
      category
      countryOfOrigin
      uom
      plu
      orderMultiple
      cartonCost
      unitCost
      unitRrp
      lastOrderQuantity
      temporarilyOutOfStock
      endOfSeason
      newArticle
    }
  }
}
    `;

/**
 * __useGetArticlesBySubCategoryQuery__
 *
 * To run a query within a React component, call `useGetArticlesBySubCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesBySubCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesBySubCategoryQuery({
 *   variables: {
 *      locationNumber: // value for 'locationNumber'
 *      subCategoryHierarchyNode: // value for 'subCategoryHierarchyNode'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetArticlesBySubCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetArticlesBySubCategoryQuery, GetArticlesBySubCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticlesBySubCategoryQuery, GetArticlesBySubCategoryQueryVariables>(GetArticlesBySubCategoryDocument, options);
      }
export function useGetArticlesBySubCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesBySubCategoryQuery, GetArticlesBySubCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticlesBySubCategoryQuery, GetArticlesBySubCategoryQueryVariables>(GetArticlesBySubCategoryDocument, options);
        }
export type GetArticlesBySubCategoryQueryHookResult = ReturnType<typeof useGetArticlesBySubCategoryQuery>;
export type GetArticlesBySubCategoryLazyQueryHookResult = ReturnType<typeof useGetArticlesBySubCategoryLazyQuery>;
export type GetArticlesBySubCategoryQueryResult = Apollo.QueryResult<GetArticlesBySubCategoryQuery, GetArticlesBySubCategoryQueryVariables>;
export const CopyOrderDocument = gql`
    mutation copyOrder($orderInput: CopyOrderInput!) {
  copyOrder(copyOrder: $orderInput) {
    item {
      id
      storeId
      status
      rosterId
      deliveryDate
      articleCount
      numberOfCartons
      grossProfitPercentage
      recommendedRetailPrice
      totalCost
      sourceOfSupplyName
      failedArticles {
        articleNumber
        description
      }
      articles {
        articleNumber
        requestedQuantity
        acknowledgedQuantity
        invoicedQuantity
        invoiceReferenceNumber
        countryOfOrigin
      }
    }
    hasErrors
    messages
  }
}
    `;
export type CopyOrderMutationFn = Apollo.MutationFunction<CopyOrderMutation, CopyOrderMutationVariables>;

/**
 * __useCopyOrderMutation__
 *
 * To run a mutation, you first call `useCopyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyOrderMutation, { data, loading, error }] = useCopyOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useCopyOrderMutation(baseOptions?: Apollo.MutationHookOptions<CopyOrderMutation, CopyOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyOrderMutation, CopyOrderMutationVariables>(CopyOrderDocument, options);
      }
export type CopyOrderMutationHookResult = ReturnType<typeof useCopyOrderMutation>;
export type CopyOrderMutationResult = Apollo.MutationResult<CopyOrderMutation>;
export type CopyOrderMutationOptions = Apollo.BaseMutationOptions<CopyOrderMutation, CopyOrderMutationVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($orderInput: CreateOrderInput!) {
  createOrder(newOrder: $orderInput) {
    item {
      id
      isPendingOrder
      storeId
      status
      rosterId
      deliveryDate
      articleCount
      numberOfCartons
      grossProfitPercentage
      recommendedRetailPrice
      totalCost
      sourceOfSupplyName
      sourceOfSupplyId
      articles {
        articleNumber
        description
        requestedQuantity
        acknowledgedQuantity
        invoicedQuantity
        invoiceReferenceNumber
        uom
        orderMultiple
        unitCost
        unitRrp
        cartonCost
      }
    }
    hasErrors
    messages
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const GetCurrentOrderByStoreDocument = gql`
    query GetCurrentOrderByStore($storeId: String!) {
  currentOrderByStore(storeId: $storeId) {
    item {
      id
      isPendingOrder
      storeId
      status
      rosterId
      deliveryDate
      articleCount
      numberOfCartons
      grossProfitPercentage
      recommendedRetailPrice
      totalCost
      sourceOfSupplyName
      sourceOfSupplyId
      articles {
        articleNumber
        description
        requestedQuantity
        acknowledgedUnits
        acknowledgedQuantity
        invoicedQuantity
        invoiceReferenceNumber
        uom
        orderMultiple
        unitCost
        unitRrp
        cartonCost
        countryOfOrigin
      }
    }
  }
}
    `;

/**
 * __useGetCurrentOrderByStoreQuery__
 *
 * To run a query within a React component, call `useGetCurrentOrderByStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOrderByStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOrderByStoreQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetCurrentOrderByStoreQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentOrderByStoreQuery, GetCurrentOrderByStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentOrderByStoreQuery, GetCurrentOrderByStoreQueryVariables>(GetCurrentOrderByStoreDocument, options);
      }
export function useGetCurrentOrderByStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentOrderByStoreQuery, GetCurrentOrderByStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentOrderByStoreQuery, GetCurrentOrderByStoreQueryVariables>(GetCurrentOrderByStoreDocument, options);
        }
export type GetCurrentOrderByStoreQueryHookResult = ReturnType<typeof useGetCurrentOrderByStoreQuery>;
export type GetCurrentOrderByStoreLazyQueryHookResult = ReturnType<typeof useGetCurrentOrderByStoreLazyQuery>;
export type GetCurrentOrderByStoreQueryResult = Apollo.QueryResult<GetCurrentOrderByStoreQuery, GetCurrentOrderByStoreQueryVariables>;
export const DeleteArticleDocument = gql`
    mutation deleteArticle($orderId: String!, $articleNumber: String!, $storeId: String!) {
      deleteArticleInOrder(orderId: $orderId, articleNumber: $articleNumber, storeId: $storeId) {
        item {
          articleNumber
        }
        hasErrors
        messages
      }
  }
`;
export type DeleteArticleMutationFn = Apollo.MutationFunction<DeleteArticleMutation, DeleteArticleMutationVariables>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      articleNumber: // value for 'articleNumber'
 *   },
 * });
 */
export function useDeleteArticleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(DeleteArticleDocument, options);
      }
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<DeleteArticleMutation, DeleteArticleMutationVariables>;
export const DeleteArticlesDocument = gql`
    mutation deleteArticles($orderId: String, $articleNumbers: [String], $storeId: String) {
  deleteArticles(orderId: $orderId, articleNumbers: $articleNumbers, storeId: $storeId) {
    item {
      articleNumber
    }
    hasErrors
    messages
  }
}
    `;
export type DeleteArticlesMutationFn = Apollo.MutationFunction<DeleteArticlesMutation, DeleteArticlesMutationVariables>;

/**
 * __useDeleteArticlesMutation__
 *
 * To run a mutation, you first call `useDeleteArticlesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticlesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticlesMutation, { data, loading, error }] = useDeleteArticlesMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      articleNumbers: // value for 'articleNumbers'
 *   },
 * });
 */
export function useDeleteArticlesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArticlesMutation, DeleteArticlesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArticlesMutation, DeleteArticlesMutationVariables>(DeleteArticlesDocument, options);
      }
export type DeleteArticlesMutationHookResult = ReturnType<typeof useDeleteArticlesMutation>;
export type DeleteArticlesMutationResult = Apollo.MutationResult<DeleteArticlesMutation>;
export type DeleteArticlesMutationOptions = Apollo.BaseMutationOptions<DeleteArticlesMutation, DeleteArticlesMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($orderId: String!) {
  deleteOrder(orderId: $orderId) {
    item {
      id
      storeId
      rosterId
    }
    hasErrors
    messages
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const GetOrderByOrderIdDocument = gql`
    query GetOrderByOrderId($orderId: String!, $storeId: String!, $isPendingOrder: Boolean!) {
  retrieveOrder(
    orderId: $orderId
    storeId: $storeId
    isPendingOrder: $isPendingOrder
  ) {
    item {
      status
      id
      isPendingOrder
      storeId
      rosterId
      deliveryDate
      numberOfCartons
      grossProfitPercentage
      recommendedRetailPrice
      totalCost
      sourceOfSupplyName
      sourceOfSupplyId
      articles {
        articleNumber
        description
        requestedQuantity
        orderMultiple
        acknowledgedUnits
        acknowledgedQuantity
        acknowledgedOrderMultiple
        invoicedQuantity
        invoicedOrderMultiple
        invoiceReferenceNumber
        uom
        unitCost
        unitRrp
        cartonCost
        temporarilyOutOfStock
        endOfSeason
        newArticle
        countryOfOrigin
        lineStatuses {
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrderByOrderIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByOrderIdQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      storeId: // value for 'storeId'
 *      isPendingOrder: // value for 'isPendingOrder'
 *   },
 * });
 */
export function useGetOrderByOrderIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrderByOrderIdQuery, GetOrderByOrderIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderByOrderIdQuery, GetOrderByOrderIdQueryVariables>(GetOrderByOrderIdDocument, options);
      }
export function useGetOrderByOrderIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderByOrderIdQuery, GetOrderByOrderIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderByOrderIdQuery, GetOrderByOrderIdQueryVariables>(GetOrderByOrderIdDocument, options);
        }
export type GetOrderByOrderIdQueryHookResult = ReturnType<typeof useGetOrderByOrderIdQuery>;
export type GetOrderByOrderIdLazyQueryHookResult = ReturnType<typeof useGetOrderByOrderIdLazyQuery>;
export type GetOrderByOrderIdQueryResult = Apollo.QueryResult<GetOrderByOrderIdQuery, GetOrderByOrderIdQueryVariables>;
export const GetordersByStoreDocument = gql`
    query GetordersByStore($storeId: String!, $limit: Int, $offset: Int, $submittedDateUtc: String, $deliveryDateUtc: String, $orderStatus: String) {
  ordersByStore(
    storeId: $storeId
    limit: $limit
    offset: $offset
    submittedDateUtc: $submittedDateUtc
    deliveryDateUtc: $deliveryDateUtc
    orderStatus: $orderStatus
  ) {
    items {
      id
      isPendingOrder
      status
      sourceOfSupplyName
      deliveryDate
      articleCount
      submittedDateTime
    }
    itemCount
    hasNextPage
    hasPreviousPage
    totalCount
    hasErrors
    messages
  }
}
    `;

/**
 * __useGetordersByStoreQuery__
 *
 * To run a query within a React component, call `useGetordersByStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetordersByStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetordersByStoreQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      submittedDateUtc: // value for 'submittedDateUtc'
 *      deliveryDateUtc: // value for 'deliveryDateUtc'
 *      orderStatus: // value for 'orderStatus'
 *   },
 * });
 */
export function useGetordersByStoreQuery(baseOptions: Apollo.QueryHookOptions<GetordersByStoreQuery, GetordersByStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetordersByStoreQuery, GetordersByStoreQueryVariables>(GetordersByStoreDocument, options);
      }
export function useGetordersByStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetordersByStoreQuery, GetordersByStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetordersByStoreQuery, GetordersByStoreQueryVariables>(GetordersByStoreDocument, options);
        }
export type GetordersByStoreQueryHookResult = ReturnType<typeof useGetordersByStoreQuery>;
export type GetordersByStoreLazyQueryHookResult = ReturnType<typeof useGetordersByStoreLazyQuery>;
export type GetordersByStoreQueryResult = Apollo.QueryResult<GetordersByStoreQuery, GetordersByStoreQueryVariables>;
export const GetReportDocument = gql`
    query GetReport($reportParams: ReportParams) {
  report(reportParams: $reportParams) {
    item {
      fileContent
    }
    hasErrors
    messages
  }
}
    `;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      reportParams: // value for 'reportParams'
 *   },
 * });
 */
export function useGetReportQuery(baseOptions?: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const GetRosterDocument = gql`
    query GetRoster($offset: Int, $limit: Int, $siteNumber: String!) {
  storeOrderRostersBySite(limit: $limit, offset: $offset, siteNumber: $siteNumber) {
    items {
      id
      scheduleId
      scheduleName
      processDateTimeUtc
      cutOffDateTimeUtc
      dispatchDateTimeUtc
      rosterDateTimeUtc
      deliveryDateTimeUtc
      siteNumber
      isActive
      sourceOfSupplyName
      sourceOfSupplyNumber
    }
    totalCount
    hasNextPage
    hasPreviousPage
  }
}
    `;

/**
 * __useGetRosterQuery__
 *
 * To run a query within a React component, call `useGetRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRosterQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      siteNumber: // value for 'siteNumber'
 *   },
 * });
 */
export function useGetRosterQuery(baseOptions: Apollo.QueryHookOptions<GetRosterQuery, GetRosterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRosterQuery, GetRosterQueryVariables>(GetRosterDocument, options);
      }
export function useGetRosterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRosterQuery, GetRosterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRosterQuery, GetRosterQueryVariables>(GetRosterDocument, options);
        }
export type GetRosterQueryHookResult = ReturnType<typeof useGetRosterQuery>;
export type GetRosterLazyQueryHookResult = ReturnType<typeof useGetRosterLazyQuery>;
export type GetRosterQueryResult = Apollo.QueryResult<GetRosterQuery, GetRosterQueryVariables>;
export const GetStoresDocument = gql`
    query GetStores($offset: Int, $limit: Int) {
  sites(offset: $offset, limit: $limit) {
    items {
      siteNumber
      siteName
      siteType
      divisionNumber
    }
  }
}
    `;

/**
 * __useGetStoresQuery__
 *
 * To run a query within a React component, call `useGetStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoresQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, options);
      }
export function useGetStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, options);
        }
export type GetStoresQueryHookResult = ReturnType<typeof useGetStoresQuery>;
export type GetStoresLazyQueryHookResult = ReturnType<typeof useGetStoresLazyQuery>;
export type GetStoresQueryResult = Apollo.QueryResult<GetStoresQuery, GetStoresQueryVariables>;
export const GetSubCategoriesDocument = gql`
    query GetSubCategories($departmentHierarchyNode: String, $categoryHierarchyNode: String, $storeId: String, $dc: String) {
  subCategoriesWithFilters(
    departmentHierarchyNode: $departmentHierarchyNode
    categoryHierarchyNode: $categoryHierarchyNode
    storeId: $storeId
    dc: $dc
  ) {
    items {
      name
      id
      category
      numberOfArticles
    }
  }
}
    `;

/**
 * __useGetSubCategoriesQuery__
 *
 * To run a query within a React component, call `useGetSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubCategoriesQuery({
 *   variables: {
 *      departmentHierarchyNode: // value for 'departmentHierarchyNode'
 *      categoryHierarchyNode: // value for 'categoryHierarchyNode'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetSubCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSubCategoriesQuery, GetSubCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubCategoriesQuery, GetSubCategoriesQueryVariables>(GetSubCategoriesDocument, options);
      }
export function useGetSubCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubCategoriesQuery, GetSubCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubCategoriesQuery, GetSubCategoriesQueryVariables>(GetSubCategoriesDocument, options);
        }
export type GetSubCategoriesQueryHookResult = ReturnType<typeof useGetSubCategoriesQuery>;
export type GetSubCategoriesLazyQueryHookResult = ReturnType<typeof useGetSubCategoriesLazyQuery>;
export type GetSubCategoriesQueryResult = Apollo.QueryResult<GetSubCategoriesQuery, GetSubCategoriesQueryVariables>;
export const SubmitOrderDocument = gql`
    mutation submitOrder($orderId: String!, $submittedBy: String!, $storeId: String!) {
  submitOrder(orderId: $orderId, submittedBy: $submittedBy, storeId: $storeId) {
    item {
      id
      submittedDateTime
      articleCount
      sourceOfSupplyId
      submittedDateTime
      status
    }
    hasErrors
    messages
  }
}
    `;
export type SubmitOrderMutationFn = Apollo.MutationFunction<SubmitOrderMutation, SubmitOrderMutationVariables>;

/**
 * __useSubmitOrderMutation__
 *
 * To run a mutation, you first call `useSubmitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOrderMutation, { data, loading, error }] = useSubmitOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      submittedBy: // value for 'submittedBy'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useSubmitOrderMutation(baseOptions?: Apollo.MutationHookOptions<SubmitOrderMutation, SubmitOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitOrderMutation, SubmitOrderMutationVariables>(SubmitOrderDocument, options);
      }
export type SubmitOrderMutationHookResult = ReturnType<typeof useSubmitOrderMutation>;
export type SubmitOrderMutationResult = Apollo.MutationResult<SubmitOrderMutation>;
export type SubmitOrderMutationOptions = Apollo.BaseMutationOptions<SubmitOrderMutation, SubmitOrderMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation updateArticle($article: UpdateArticleInput!) {
  updateArticleInOrder(article: $article) {
    item {
      articleNumber
    }
    hasErrors
    messages
  }
}
    `;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      article: // value for 'article'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($orderToUpdate: UpdateOrderInput!) {
  updateOrder(orderToUpdate: $orderToUpdate) {
    item {
      id
      storeId
      rosterId
    }
    hasErrors
    messages
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      orderToUpdate: // value for 'orderToUpdate'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;


/**
 * __useUpdateStoreForecastMutation__
 *
 * To run a mutation, you first call `useUpdateStoreForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useUpdateStoreForecastMutation, { data, loading, error }] = useUpdateStoreForecastMutation({
 *   variables: {
 *      orderToUpdate: // value for 'orderToUpdate'
 *   },
 * });
 */
export function useUpdateStoreForecastMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreForecastMutation, UpdateStoreForecastMutationVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateStoreForecastMutation, UpdateStoreForecastMutationVariables>(UpdateForecastDocument, options);
}
export type UpdateStoreForecastMutationHookResult = ReturnType<typeof useUpdateStoreForecastMutation>;
export type UpdateStoreForecastMutationResult = Apollo.MutationResult<UpdateStoreForecastMutation>;
export type UpdateStoreForecastMutationOptions = Apollo.BaseMutationOptions<UpdateStoreForecastMutation, UpdateStoreForecastMutationVariables>;


/**
 * __useUpdateDcForecastMutation__
 *
 * To run a mutation, you first call `useUpdateDcForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDcForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useUpdateDcForecastMutation, { data, loading, error }] = useUpdateDcForecastMutation({
 *   variables: {
 *      orderToUpdate: // value for 'orderToUpdate'
 *   },
 * });
 */
export function useUpdateDcForecastMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDcForecastMutation, UpdateDcForecastMutationVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateDcForecastMutation, UpdateDcForecastMutationVariables>(updateDcForecastDocument, options);
}
export type UpdateDcForecastMutationHookResult = ReturnType<typeof useUpdateDcForecastMutation>;
export type UpdateDcForecastMutationResult = Apollo.MutationResult<UpdateDcForecastMutation>;
export type UpdateDcForecastMutationOptions = Apollo.BaseMutationOptions<UpdateDcForecastMutation, UpdateDcForecastMutationVariables>;



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddArticleInput: AddArticleInput;
  ApplyPolicy: ApplyPolicy;
  Article: ResolverTypeWrapper<Article>;
  ArticleMutationResponse: ResolverTypeWrapper<ArticleMutationResponse>;
  ArticlesPagedResponse: ResolverTypeWrapper<ArticlesPagedResponse>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Category: ResolverTypeWrapper<Category>;
  CategoryItemsResponse: ResolverTypeWrapper<CategoryItemsResponse>;
  CopyOrderDetail: ResolverTypeWrapper<CopyOrderDetail>;
  CopyOrderInput: CopyOrderInput;
  CopyOrderMutationResponse: ResolverTypeWrapper<CopyOrderMutationResponse>;
  CreateOrderInput: CreateOrderInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DcForecast: ResolverTypeWrapper<DcForecast>;
  DcForecastResponse: ResolverTypeWrapper<DcForecastResponse>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Forecast: ResolverTypeWrapper<Forecast>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Long: ResolverTypeWrapper<Scalars['Long']>;
  MultiplierPath: ResolverTypeWrapper<Scalars['MultiplierPath']>;
  Mutation: ResolverTypeWrapper<{}>;
  MutationArticle: ResolverTypeWrapper<MutationArticle>;
  MutationDcForecastServiceResponse: ResolverTypeWrapper<MutationDcForecastServiceResponse>;
  MutationStoreForecastServiceResponse: ResolverTypeWrapper<MutationStoreForecastServiceResponse>;
  OrderArticle: ResolverTypeWrapper<OrderArticle>;
  OrderArticleStatus: ResolverTypeWrapper<OrderArticleStatus>;
  OrderDetail: ResolverTypeWrapper<OrderDetail>;
  OrderItemResponse: ResolverTypeWrapper<OrderItemResponse>;
  OrderMutationResponse: ResolverTypeWrapper<OrderMutationResponse>;
  OrderPagedResponse: ResolverTypeWrapper<OrderPagedResponse>;
  OrderSummary: ResolverTypeWrapper<OrderSummary>;
  PagedResponse: ResolversTypes['ArticlesPagedResponse'] | ResolversTypes['DcForecastResponse'] | ResolversTypes['OrderPagedResponse'] | ResolversTypes['SitePagedResponse'] | ResolversTypes['SourceOfSupplyPagedItemResponse'] | ResolversTypes['StoreOrderRostersPagedResponse'] | ResolversTypes['StoreRetailForecastResponse'];
  Query: ResolverTypeWrapper<{}>;
  Report: ResolverTypeWrapper<Report>;
  ReportArticlesSelectionType: ReportArticlesSelectionType;
  ReportItemResponse: ResolverTypeWrapper<ReportItemResponse>;
  ReportParams: ReportParams;
  ReportType: ReportType;
  SalesForecast: ResolverTypeWrapper<SalesForecast>;
  Site: ResolverTypeWrapper<Site>;
  SiteItemResponse: ResolverTypeWrapper<SiteItemResponse>;
  SitePagedResponse: ResolverTypeWrapper<SitePagedResponse>;
  SourceOfSupply: ResolverTypeWrapper<SourceOfSupply>;
  SourceOfSupplyItemResponse: ResolverTypeWrapper<SourceOfSupplyItemResponse>;
  SourceOfSupplyPagedItemResponse: ResolverTypeWrapper<SourceOfSupplyPagedItemResponse>;
  StatusType: StatusType;
  StoreOrderRoster: ResolverTypeWrapper<StoreOrderRoster>;
  StoreOrderRostersPagedResponse: ResolverTypeWrapper<StoreOrderRostersPagedResponse>;
  StoreRetailForecast: ResolverTypeWrapper<StoreRetailForecast>;
  StoreRetailForecastResponse: ResolverTypeWrapper<StoreRetailForecastResponse>;
  String: ResolverTypeWrapper<Scalars['String']>;
  SubCategory: ResolverTypeWrapper<SubCategory>;
  SubCategoryItemsResponse: ResolverTypeWrapper<SubCategoryItemsResponse>;
  UpdateArticleInput: UpdateArticleInput;
  UpdateDcForecastInput: UpdateDcForecastInput;
  UpdateOrderInput: UpdateOrderInput;
  UpdateStoreForecastInput: UpdateStoreForecastInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddArticleInput: AddArticleInput;
  Article: Article;
  ArticleMutationResponse: ArticleMutationResponse;
  ArticlesPagedResponse: ArticlesPagedResponse;
  Boolean: Scalars['Boolean'];
  Category: Category;
  CategoryItemsResponse: CategoryItemsResponse;
  CopyOrderDetail: CopyOrderDetail;
  CopyOrderInput: CopyOrderInput;
  CopyOrderMutationResponse: CopyOrderMutationResponse;
  CreateOrderInput: CreateOrderInput;
  DateTime: Scalars['DateTime'];
  DcForecast: DcForecast;
  DcForecastResponse: DcForecastResponse;
  Float: Scalars['Float'];
  Forecast: Forecast;
  Int: Scalars['Int'];
  Long: Scalars['Long'];
  MultiplierPath: Scalars['MultiplierPath'];
  Mutation: {};
  MutationArticle: MutationArticle;
  MutationDcForecastServiceResponse: MutationDcForecastServiceResponse;
  MutationStoreForecastServiceResponse: MutationStoreForecastServiceResponse;
  OrderArticle: OrderArticle;
  OrderArticleStatus: OrderArticleStatus;
  OrderDetail: OrderDetail;
  OrderItemResponse: OrderItemResponse;
  OrderMutationResponse: OrderMutationResponse;
  OrderPagedResponse: OrderPagedResponse;
  OrderSummary: OrderSummary;
  PagedResponse: ResolversParentTypes['ArticlesPagedResponse'] | ResolversParentTypes['DcForecastResponse'] | ResolversParentTypes['OrderPagedResponse'] | ResolversParentTypes['SitePagedResponse'] | ResolversParentTypes['SourceOfSupplyPagedItemResponse'] | ResolversParentTypes['StoreOrderRostersPagedResponse'] | ResolversParentTypes['StoreRetailForecastResponse'];
  Query: {};
  Report: Report;
  ReportItemResponse: ReportItemResponse;
  ReportParams: ReportParams;
  SalesForecast: SalesForecast;
  Site: Site;
  SiteItemResponse: SiteItemResponse;
  SitePagedResponse: SitePagedResponse;
  SourceOfSupply: SourceOfSupply;
  SourceOfSupplyItemResponse: SourceOfSupplyItemResponse;
  SourceOfSupplyPagedItemResponse: SourceOfSupplyPagedItemResponse;
  StoreOrderRoster: StoreOrderRoster;
  StoreOrderRostersPagedResponse: StoreOrderRostersPagedResponse;
  StoreRetailForecast: StoreRetailForecast;
  StoreRetailForecastResponse: StoreRetailForecastResponse;
  String: Scalars['String'];
  SubCategory: SubCategory;
  SubCategoryItemsResponse: SubCategoryItemsResponse;
  UpdateArticleInput: UpdateArticleInput;
  UpdateDcForecastInput: UpdateDcForecastInput;
  UpdateOrderInput: UpdateOrderInput;
  UpdateStoreForecastInput: UpdateStoreForecastInput;
};

export type AuthorizeDirectiveArgs = {
  apply?: ApplyPolicy;
  policy: Maybe<Scalars['String']>;
  roles: Maybe<Array<Scalars['String']>>;
};

export type AuthorizeDirectiveResolver<Result, Parent, ContextType = any, Args = AuthorizeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type CostDirectiveArgs = {
  complexity?: Scalars['Int'];
  defaultMultiplier: Maybe<Scalars['Int']>;
  multipliers: Maybe<Array<Scalars['MultiplierPath']>>;
};

export type CostDirectiveResolver<Result, Parent, ContextType = any, Args = CostDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DeferDirectiveArgs = {
  if: Maybe<Scalars['Boolean']>;
  label: Maybe<Scalars['String']>;
};

export type DeferDirectiveResolver<Result, Parent, ContextType = any, Args = DeferDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type LowerDirectiveArgs = { };

export type LowerDirectiveResolver<Result, Parent, ContextType = any, Args = LowerDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type StreamDirectiveArgs = {
  if: Maybe<Scalars['Boolean']>;
  initialCount?: Scalars['Int'];
  label: Maybe<Scalars['String']>;
};

export type StreamDirectiveResolver<Result, Parent, ContextType = any, Args = StreamDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type TitleDirectiveArgs = { };

export type TitleDirectiveResolver<Result, Parent, ContextType = any, Args = TitleDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type UpperDirectiveArgs = { };

export type UpperDirectiveResolver<Result, Parent, ContextType = any, Args = UpperDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article'] = ResolversParentTypes['Article']> = {
  articleNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cartonCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  category: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryOfOrigin: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endOfSeason: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastOrderQuantity: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  newArticle: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  orderMultiple: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  plu: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subCategory: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  temporarilyOutOfStock: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  unitCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  unitRrp: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  uom: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleMutationResponse'] = ResolversParentTypes['ArticleMutationResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['MutationArticle']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesPagedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesPagedResponse'] = ResolversParentTypes['ArticlesPagedResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items: Resolver<Array<ResolversTypes['Article']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> = {
  departmentId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryItemsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryItemsResponse'] = ResolversParentTypes['CategoryItemsResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CopyOrderDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['CopyOrderDetail'] = ResolversParentTypes['CopyOrderDetail']> = {
  articleCount: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  articles: Resolver<Maybe<Array<Maybe<ResolversTypes['OrderArticle']>>>, ParentType, ContextType>;
  deliveryDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedArticles: Resolver<Maybe<Array<Maybe<ResolversTypes['OrderArticle']>>>, ParentType, ContextType>;
  grossProfitPercentage: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfCartons: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  recommendedRetailPrice: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rosterId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  sourceOfSupplyId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceOfSupplyName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submittedDateTime: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CopyOrderMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CopyOrderMutationResponse'] = ResolversParentTypes['CopyOrderMutationResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['CopyOrderDetail']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DcForecastResolvers<ContextType = any, ParentType extends ResolversParentTypes['DcForecast'] = ResolversParentTypes['DcForecast']> = {
  articleNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  financialWeekEndDate: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  financialWeekStartDate: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  forecast: Resolver<Array<Maybe<ResolversTypes['Forecast']>>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subCategory: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uom: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DcForecastResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DcForecastResponse'] = ResolversParentTypes['DcForecastResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items: Resolver<Maybe<Array<Maybe<ResolversTypes['DcForecast']>>>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForecastResolvers<ContextType = any, ParentType extends ResolversParentTypes['Forecast'] = ResolversParentTypes['Forecast']> = {
  date: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  enteredQty: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export interface MultiplierPathScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['MultiplierPath'], any> {
  name: 'MultiplierPath';
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addArticleToOrder: Resolver<Maybe<ResolversTypes['ArticleMutationResponse']>, ParentType, ContextType, RequireFields<MutationAddArticleToOrderArgs, 'newArticle'>>;
  copyOrder: Resolver<Maybe<ResolversTypes['CopyOrderMutationResponse']>, ParentType, ContextType, RequireFields<MutationCopyOrderArgs, 'copyOrder'>>;
  createOrder: Resolver<Maybe<ResolversTypes['OrderMutationResponse']>, ParentType, ContextType, RequireFields<MutationCreateOrderArgs, 'newOrder'>>;
  deleteArticleInOrder: Resolver<Maybe<ResolversTypes['ArticleMutationResponse']>, ParentType, ContextType, RequireFields<MutationDeleteArticleInOrderArgs, 'articleNumber' | 'orderId'>>;
  deleteArticles: Resolver<Maybe<ResolversTypes['ArticleMutationResponse']>, ParentType, ContextType, Partial<MutationDeleteArticlesArgs>>;
  deleteOrder: Resolver<Maybe<ResolversTypes['OrderMutationResponse']>, ParentType, ContextType, RequireFields<MutationDeleteOrderArgs, 'orderId'>>;
  submitOrder: Resolver<Maybe<ResolversTypes['OrderMutationResponse']>, ParentType, ContextType, RequireFields<MutationSubmitOrderArgs, 'orderId' | 'storeId' | 'submittedBy'>>;
  updateArticleInOrder: Resolver<Maybe<ResolversTypes['ArticleMutationResponse']>, ParentType, ContextType, RequireFields<MutationUpdateArticleInOrderArgs, 'article'>>;
  updateDcForecast: Resolver<Maybe<ResolversTypes['MutationDcForecastServiceResponse']>, ParentType, ContextType, Partial<MutationUpdateDcForecastArgs>>;
  updateOrder: Resolver<Maybe<ResolversTypes['OrderMutationResponse']>, ParentType, ContextType, RequireFields<MutationUpdateOrderArgs, 'orderToUpdate'>>;
  updateStoreForecast: Resolver<Maybe<ResolversTypes['MutationStoreForecastServiceResponse']>, ParentType, ContextType, Partial<MutationUpdateStoreForecastArgs>>;
};

export type MutationArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationArticle'] = ResolversParentTypes['MutationArticle']> = {
  articleNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationDcForecastServiceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationDcForecastServiceResponse'] = ResolversParentTypes['MutationDcForecastServiceResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationStoreForecastServiceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationStoreForecastServiceResponse'] = ResolversParentTypes['MutationStoreForecastServiceResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderArticle'] = ResolversParentTypes['OrderArticle']> = {
  acknowledgedOrderMultiple: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  acknowledgedQuantity: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  acknowledgedUnits: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  articleNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cartonCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  countryOfOrigin: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endOfSeason: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invoiceReferenceNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoicedOrderMultiple: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoicedQuantity: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lineStatuses: Resolver<Maybe<Array<Maybe<ResolversTypes['OrderArticleStatus']>>>, ParentType, ContextType>;
  newArticle: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  orderMultiple: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  requestedQuantity: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  stockOnOrder: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  temporarilyOutOfStock: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  unitCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  unitRrp: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  uom: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderArticleStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderArticleStatus'] = ResolversParentTypes['OrderArticleStatus']> = {
  code: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderDetail'] = ResolversParentTypes['OrderDetail']> = {
  articleCount: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  articles: Resolver<Maybe<Array<Maybe<ResolversTypes['OrderArticle']>>>, ParentType, ContextType>;
  deliveryDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  grossProfitPercentage: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isPendingOrder: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  numberOfCartons: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  purchaseOrderNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recommendedRetailPrice: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rosterId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  sourceOfSupplyId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceOfSupplyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceReferenceId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storeId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submittedDateTime: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderItemResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderItemResponse'] = ResolversParentTypes['OrderItemResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['OrderDetail']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderMutationResponse'] = ResolversParentTypes['OrderMutationResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['OrderDetail']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderPagedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPagedResponse'] = ResolversParentTypes['OrderPagedResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items: Resolver<Maybe<Array<Maybe<ResolversTypes['OrderSummary']>>>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderSummary'] = ResolversParentTypes['OrderSummary']> = {
  articleCount: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deliveryDate: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isPendingOrder: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  purchaseOrderNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceOfSupplyId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceOfSupplyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceReferenceId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status: Resolver<ResolversTypes['StatusType'], ParentType, ContextType>;
  submittedDateTime: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedResponse'] = ResolversParentTypes['PagedResponse']> = {
  __resolveType: TypeResolveFn<'ArticlesPagedResponse' | 'DcForecastResponse' | 'OrderPagedResponse' | 'SitePagedResponse' | 'SourceOfSupplyPagedItemResponse' | 'StoreOrderRostersPagedResponse' | 'StoreRetailForecastResponse', ParentType, ContextType>;
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  articles: Resolver<Maybe<ResolversTypes['ArticlesPagedResponse']>, ParentType, ContextType, Partial<QueryArticlesArgs>>;
  articlesBySubCategory: Resolver<Maybe<ResolversTypes['ArticlesPagedResponse']>, ParentType, ContextType, RequireFields<QueryArticlesBySubCategoryArgs, 'locationNumber' | 'subCategoryHierarchyNode'>>;
  categories: Resolver<Maybe<ResolversTypes['CategoryItemsResponse']>, ParentType, ContextType>;
  currentOrderByStore: Resolver<Maybe<ResolversTypes['OrderItemResponse']>, ParentType, ContextType, RequireFields<QueryCurrentOrderByStoreArgs, 'storeId'>>;
  dcForecast: Resolver<Maybe<ResolversTypes['DcForecastResponse']>, ParentType, ContextType, Partial<QueryDcForecastArgs>>;
  ordersByStore: Resolver<Maybe<ResolversTypes['OrderPagedResponse']>, ParentType, ContextType, RequireFields<QueryOrdersByStoreArgs, 'storeId'>>;
  report: Resolver<Maybe<ResolversTypes['ReportItemResponse']>, ParentType, ContextType, Partial<QueryReportArgs>>;
  retrieveOrder: Resolver<Maybe<ResolversTypes['OrderItemResponse']>, ParentType, ContextType, RequireFields<QueryRetrieveOrderArgs, 'isPendingOrder' | 'orderId' | 'storeId'>>;
  siteBySiteNumber: Resolver<Maybe<ResolversTypes['SiteItemResponse']>, ParentType, ContextType, RequireFields<QuerySiteBySiteNumberArgs, 'siteNumber'>>;
  sites: Resolver<Maybe<ResolversTypes['SitePagedResponse']>, ParentType, ContextType, Partial<QuerySitesArgs>>;
  sourceOfSupplyBySourceOfSupplyNumber: Resolver<Maybe<ResolversTypes['SourceOfSupplyItemResponse']>, ParentType, ContextType, Partial<QuerySourceOfSupplyBySourceOfSupplyNumberArgs>>;
  sourceOfSupplyList: Resolver<Maybe<ResolversTypes['SourceOfSupplyPagedItemResponse']>, ParentType, ContextType>;
  storeOrderRosters: Resolver<Maybe<ResolversTypes['StoreOrderRostersPagedResponse']>, ParentType, ContextType, Partial<QueryStoreOrderRostersArgs>>;
  storeOrderRostersBySite: Resolver<Maybe<ResolversTypes['StoreOrderRostersPagedResponse']>, ParentType, ContextType, RequireFields<QueryStoreOrderRostersBySiteArgs, 'siteNumber'>>;
  storeRetailForecast: Resolver<Maybe<ResolversTypes['StoreRetailForecastResponse']>, ParentType, ContextType, Partial<QueryStoreRetailForecastArgs>>;
  subCategoriesWithFilters: Resolver<Maybe<ResolversTypes['SubCategoryItemsResponse']>, ParentType, ContextType, Partial<QuerySubCategoriesWithFiltersArgs>>;
};

export type ReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  fileContent: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportItemResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportItemResponse'] = ResolversParentTypes['ReportItemResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForecastResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForecast'] = ResolversParentTypes['SalesForecast']> = {
  date: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  enteredQty: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sale: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Site'] = ResolversParentTypes['Site']> = {
  divisionNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  siteName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  siteNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  siteType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SiteItemResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SiteItemResponse'] = ResolversParentTypes['SiteItemResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['Site']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SitePagedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SitePagedResponse'] = ResolversParentTypes['SitePagedResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items: Resolver<Maybe<Array<Maybe<ResolversTypes['Site']>>>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SourceOfSupplyResolvers<ContextType = any, ParentType extends ResolversParentTypes['SourceOfSupply'] = ResolversParentTypes['SourceOfSupply']> = {
  sourceOfSupplyName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceOfSupplyNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceOfSupplyType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SourceOfSupplyItemResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SourceOfSupplyItemResponse'] = ResolversParentTypes['SourceOfSupplyItemResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['SourceOfSupply']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SourceOfSupplyPagedItemResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SourceOfSupplyPagedItemResponse'] = ResolversParentTypes['SourceOfSupplyPagedItemResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items: Resolver<Array<ResolversTypes['SourceOfSupply']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreOrderRosterResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreOrderRoster'] = ResolversParentTypes['StoreOrderRoster']> = {
  cutOffDateTimeUtc: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deliveryDateTimeUtc: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dispatchDateTimeUtc: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isActive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  processDateTimeUtc: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rosterDateTimeUtc: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduleId: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  scheduleName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceOfSupplyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceOfSupplyNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreOrderRostersPagedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreOrderRostersPagedResponse'] = ResolversParentTypes['StoreOrderRostersPagedResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items: Resolver<Array<ResolversTypes['StoreOrderRoster']>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreRetailForecastResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreRetailForecast'] = ResolversParentTypes['StoreRetailForecast']> = {
  article: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  financialWeekEndDate: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  financialWeekStartDate: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  forecast: Resolver<Array<Maybe<ResolversTypes['SalesForecast']>>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pluCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  storeId: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subCategory: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uom: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoreRetailForecastResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoreRetailForecastResponse'] = ResolversParentTypes['StoreRetailForecastResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items: Resolver<Maybe<Array<Maybe<ResolversTypes['StoreRetailForecast']>>>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubCategory'] = ResolversParentTypes['SubCategory']> = {
  category: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfArticles: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubCategoryItemsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubCategoryItemsResponse'] = ResolversParentTypes['SubCategoryItemsResponse']> = {
  hasErrors: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items: Resolver<Maybe<Array<Maybe<ResolversTypes['SubCategory']>>>, ParentType, ContextType>;
  messages: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Article: ArticleResolvers<ContextType>;
  ArticleMutationResponse: ArticleMutationResponseResolvers<ContextType>;
  ArticlesPagedResponse: ArticlesPagedResponseResolvers<ContextType>;
  Category: CategoryResolvers<ContextType>;
  CategoryItemsResponse: CategoryItemsResponseResolvers<ContextType>;
  CopyOrderDetail: CopyOrderDetailResolvers<ContextType>;
  CopyOrderMutationResponse: CopyOrderMutationResponseResolvers<ContextType>;
  DateTime: GraphQLScalarType;
  DcForecast: DcForecastResolvers<ContextType>;
  DcForecastResponse: DcForecastResponseResolvers<ContextType>;
  Forecast: ForecastResolvers<ContextType>;
  Long: GraphQLScalarType;
  MultiplierPath: GraphQLScalarType;
  Mutation: MutationResolvers<ContextType>;
  MutationArticle: MutationArticleResolvers<ContextType>;
  MutationDcForecastServiceResponse: MutationDcForecastServiceResponseResolvers<ContextType>;
  MutationStoreForecastServiceResponse: MutationStoreForecastServiceResponseResolvers<ContextType>;
  OrderArticle: OrderArticleResolvers<ContextType>;
  OrderArticleStatus: OrderArticleStatusResolvers<ContextType>;
  OrderDetail: OrderDetailResolvers<ContextType>;
  OrderItemResponse: OrderItemResponseResolvers<ContextType>;
  OrderMutationResponse: OrderMutationResponseResolvers<ContextType>;
  OrderPagedResponse: OrderPagedResponseResolvers<ContextType>;
  OrderSummary: OrderSummaryResolvers<ContextType>;
  PagedResponse: PagedResponseResolvers<ContextType>;
  Query: QueryResolvers<ContextType>;
  Report: ReportResolvers<ContextType>;
  ReportItemResponse: ReportItemResponseResolvers<ContextType>;
  SalesForecast: SalesForecastResolvers<ContextType>;
  Site: SiteResolvers<ContextType>;
  SiteItemResponse: SiteItemResponseResolvers<ContextType>;
  SitePagedResponse: SitePagedResponseResolvers<ContextType>;
  SourceOfSupply: SourceOfSupplyResolvers<ContextType>;
  SourceOfSupplyItemResponse: SourceOfSupplyItemResponseResolvers<ContextType>;
  SourceOfSupplyPagedItemResponse: SourceOfSupplyPagedItemResponseResolvers<ContextType>;
  StoreOrderRoster: StoreOrderRosterResolvers<ContextType>;
  StoreOrderRostersPagedResponse: StoreOrderRostersPagedResponseResolvers<ContextType>;
  StoreRetailForecast: StoreRetailForecastResolvers<ContextType>;
  StoreRetailForecastResponse: StoreRetailForecastResponseResolvers<ContextType>;
  SubCategory: SubCategoryResolvers<ContextType>;
  SubCategoryItemsResponse: SubCategoryItemsResponseResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  authorize: AuthorizeDirectiveResolver<any, any, ContextType>;
  cost: CostDirectiveResolver<any, any, ContextType>;
  defer: DeferDirectiveResolver<any, any, ContextType>;
  lower: LowerDirectiveResolver<any, any, ContextType>;
  stream: StreamDirectiveResolver<any, any, ContextType>;
  title: TitleDirectiveResolver<any, any, ContextType>;
  upper: UpperDirectiveResolver<any, any, ContextType>;
};
