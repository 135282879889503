import React, { useContext, ReactNode, useEffect, useState } from "react";
import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import StoreContext from "@context/StoreContext";
import { useAuth } from "react-oidc-context";
import { getConfigValue } from "../config";
import Loader from "./Loader";
import { Site } from "src/graphql/types";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

interface Organisation {
  storeId: string;
  name: string;
}

type OrgsClaim = {
  organisations: Organisation[];
};

type Props = {
  children: ReactNode;
};

const oidcConfig = getConfigValue("OIDC_CONFIG") as Record<string, string>;

const AppLoader = ({ children }: Props) => {
  const auth = useAuth();
  const appInsights = useAppInsightsContext();

  const { setStores, setStore, stores: userStores } = useContext(StoreContext);
  const [apolloClient, setApolloClient] = useState(new ApolloClient({ cache: new InMemoryCache() }));

  useEffect(() => {
    if (!auth.isAuthenticated) {
      return;
    }

    const user = auth.user;
    const organisations: Organisation[] = (user?.profile?.orgs as OrgsClaim)?.organisations || [];
    const groups: string[] = (user?.profile?.groups as string[]) || [];
    const isOkta = typeof groups !== "undefined" && groups.length > 0;

    const hasAzuregroup = groups.find(x => x.startsWith("Azure-"));

    //step 1: if its woolworths employee login or azure-group
    //1. check if no orgs
    //2. check if no groups
    //3. check if azure groups
    if (
      (organisations.length === 0 && typeof auth.user?.profile?.orgs === "undefined" && typeof auth.user?.profile?.groups === "undefined") ||
      hasAzuregroup !== undefined
    ) {
      organisations.push({
        storeId: "WDL",
        name: "WDL",
      });
    }

    let stores: Site[] = [];
    let appGroups: string[] = [];
    //step 2: if its okta login or gigya map the claims
    //now get the organisations for okta
    if (isOkta && (organisations.length === 0 || organisations == undefined)) {
      const storeGroups = groups.filter(x => x.startsWith("Store-"));
      const appUserGroup = groups.filter(x => x.startsWith("App-"));

      //get the app group list
      appUserGroup.map( app => {
        const appGroupParts = app.split("-");
        if(appGroupParts.length > 2 && (appGroupParts[2] == 'Franchise_Store' || appGroupParts[2] == 'Produce_UI')){
          appGroups.push(appGroupParts[1]);
        }
      });

      //get store group list
      storeGroups.map(store => {
        const groupParts = store.split("-");
        const duplicateStore = stores.find(x => x.siteNumber.trim() == groupParts[2].trim());

        //make sure store exist in app groups as well
        if (groupParts.length > 2 && duplicateStore == undefined && appGroups.indexOf(groupParts[1]) >= 0) {
          stores.push({
            siteNumber: groupParts[2],
            siteName: groupParts[1],
          } as Site);
        }
      });
    } else {
      //now get the organisations for gigya or for azure groups
      stores = organisations.map(organisation => {
        const { name, storeId } = organisation;
        return {
          siteNumber: storeId,
          siteName: name,
        } as Site;
      });
    }

    setStores(stores);
    const userId = auth.user?.profile.sub || "";
    const userData = JSON.parse(window.localStorage.getItem(userId) || "{}");

    setStore(userData.store || stores[0]);

    const authLink = new ApolloLink((operation, forward) => {
      const sessionStorageKey = `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`;
      const data = JSON.parse(sessionStorage.getItem(sessionStorageKey) || `{}`);

      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${data.access_token || ""}`,
        },
      }));

      return forward(operation);
    });
    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          appInsights.trackException({
            exception: new Error("GraphQL error"),
            severityLevel: SeverityLevel.Error,
            properties: {
              message,
              location,
              path,
            },
          });
        });
      }
      if (networkError) {
        appInsights.trackException({
          exception: new Error("Network error"),
          severityLevel: SeverityLevel.Error,
          properties: {
            message: networkError,
          },
        });
        console.log(`[Network error]: ${networkError}`);
      }
      forward(operation);
    });

    const httpLink = new HttpLink({
      uri: `${getConfigValue("API_URL")}/graphql`,
    });

    const apolloClient = new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          OrderArticle: {
            keyFields: ["articleNumber"],
          },
          Site: {
            keyFields: ["siteNumber"],
          },
          OrderDetail: {
            fields: {
              articles: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
        },
      }),
      link: from([errorLink, authLink, httpLink]),
      connectToDevTools: true,
    });
    setApolloClient(apolloClient);
  }, [auth.isAuthenticated]);

  return auth.isAuthenticated && userStores.length ? <ApolloProvider client={apolloClient}>{children}</ApolloProvider> : <Loader></Loader>;
};

export default AppLoader;
