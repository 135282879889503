import { Button, Logo } from "@components";
import MenuItem from "@components/Menu/MenuItem";
import OrderContext from "@context/OrderContext";
import StoreContext from "@context/StoreContext";

import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItemText,
  MenuItem as MuiMenuItem,
  NoSsr,
  Select,
  SelectChangeEvent,
  Stack,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import { Link } from "gatsby";
import { useNavigate } from "react-router-dom";
import React, { FC, useContext, useEffect, useState } from "react";
import { Category, Site, SourceOfSupply, useGetAllCategoriesQuery, useGetSourceOfSupplierQuery, useGetStoresLazyQuery } from "../../graphql/types";
import { config, generateCategoryMap } from "./config";
import Logout from "./Logout";
import SupplierContext from "@context/SupplierContext";
import { MenuList } from "./MenuList";

type MenuProps = {
  brand: "FreshChoice" | "SuperValue";
  storeName: string;
  storeNumber: number;
  collapse: boolean;
  dense: boolean;
};

const collapsedItem = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const Menu: FC<MenuProps> = ({ collapse, dense = false }) => {
  const [getSourceOfSupplier] = useGetSourceOfSupplierQuery();
  const theme = useTheme();
  const { data } = useGetAllCategoriesQuery();
  const categoriesResponse = data?.categories?.items || [];
  const categories = generateCategoryMap(categoriesResponse as Category[]);
  const activeCategories = categories.filter(category => category.text.match(/Not/) === null);

  const { order: currentOrder } = useContext(OrderContext);
  const [stores, setStores] = useState<(Site | null)[]>([]);
  const [sourceOfSuppliers, setSourceOfSuppliers] = useState<(SourceOfSupply | null)[]>([]);

  const { stores: userStores, storeId, setStore } = useContext(StoreContext);
  const { selectedSourceOfSupplier, setSelectedSourceOfSupplier } = useContext(SupplierContext);
  var currentStore = stores.find(store => store && store.siteNumber === storeId);
  if(currentStore == undefined && stores.length > 0){
    currentStore = stores[0];
  }
  const [getStores] = useGetStoresLazyQuery();
  const navigate = useNavigate();
  const { siteName = "", siteNumber } = currentStore || ({} as Site);
  const brand = siteName.match(/FreshChoice/) ? "FreshChoice" : "SuperValue";
  const shortName = siteName?.replace(`${brand} `, "");

  const { pathname } = useLocation();
  const { orderMenuItems, helpMenuItems } = config.menus;
  // adding categories to menu items
  orderMenuItems[3].items = activeCategories;

  const isForecastPage = pathname.match(/store-forecast|dc-forecast/);
  const hasOrder = !!currentOrder?.id;

  const handleClose = (store: Site) => {
    if (store?.siteNumber) {
      setStore(store);
      navigate(`/`);
    }
    return;
  };

  const handleCloseSupplier = (supplier: SourceOfSupply) => {
    if (supplier?.sourceOfSupplyNumber) {
      setSelectedSourceOfSupplier(supplier);
      navigate(pathname);
    }
    return;
  };

  useEffect(() => {
    const isAdmin = userStores.find(userStore => userStore?.siteName?.toLowerCase() === "wdl");
    if (!isAdmin) {
      setStores(userStores);
      if(orderMenuItems[4].items != null){
        //hide category manager forecast view if not an admin
        orderMenuItems[4].items[1].hide = true;
      }
      return;
    }
    getStores({
      variables: {
        offset: 0,
        limit: 1000,
      },
      initialFetchPolicy: "network-only",
      nextFetchPolicy: "cache-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const stores = data?.sites?.items || [];
        setStores(stores);
        if (storeId?.toLowerCase() === "wdl" && stores[0] !== null) {
          setStore(stores[0]);
        }
      },
    });
  }, [userStores]);

  useEffect(() => {
    if (sourceOfSuppliers.length > 0) {
      return;
    }
    getSourceOfSupplier({
      initialFetchPolicy: "network-only",
      nextFetchPolicy: "cache-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        setSourceOfSuppliers(data.sourceOfSupplyList?.items as SourceOfSupply[]);
        setSelectedSourceOfSupplier(data.sourceOfSupplyList?.items[0] as SourceOfSupply);
      },
    });
  }, [setSelectedSourceOfSupplier, sourceOfSuppliers]);

  return (
    <NoSsr>
      <Box
        id="wpp-menu"
        component={motion.nav}
        layout
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - var(--vh-offset, 0px))",
        }}
      >
        <Stack direction="column" height="100%">
          <Box px={!collapse ? 3 : 1} pt={!collapse ? 3 : 1} color="primary.main" component={Link} to="/orders">
            <Logo variant={collapse ? "icon" : "full"} />
          </Box>
          <AnimatePresence>
            {!collapse && (
              <Box sx={{ textAlign: "center", mt: 2, px: 3 }} component={motion.div} layout {...collapsedItem}>
                {stores.length === 1 ? (
                  <Box
                    sx={{
                      fontWeight: 500,
                      display: "inline-block",
                      fontSize: "16px",
                      color: theme.palette.grey[900],
                    }}
                  >{`${shortName} (${siteNumber})`}</Box>
                ) : (
                  <Box>
                    <FormControl variant="outlined" sx={{ width: 230, height: 44 }}>
                      <InputLabel id="demo-simple-select-outlined-label">Current Store</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={siteNumber || ""}
                        onChange={(event: SelectChangeEvent) => {
                          const selectedStoreId = event.target.value as string;
                          const selectedStore = stores.find(store => store?.siteNumber === selectedStoreId);
                          if (selectedStore) setStore(selectedStore);
                        }}
                        label="Store"
                        sx={{ py: 0.5, textAlign: "left" }}
                      >
                        {stores.map(store =>
                          store ? (
                            <MuiMenuItem onClick={() => handleClose(store)} key={store?.siteNumber} value={store?.siteNumber}>
                              <ListItemText>{`${store.siteName.replace(/FreshChoice/, "")} (${store.siteNumber})`}</ListItemText>
                            </MuiMenuItem>
                          ) : (
                            <></>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {isForecastPage ? (
                  <Box sx={{ my: 2 }}>
                    <FormControl variant="outlined" sx={{ width: 230, height: 44 }}>
                      <InputLabel id="suppliers-dropdown">Current DC</InputLabel>
                      <Select
                        labelId="suppliers-dropdown"
                        id="supplier-dropdown"
                        value={selectedSourceOfSupplier?.sourceOfSupplyNumber || ""}
                        label="Supplier"
                        sx={{ py: 0.5, textAlign: "left" }}
                      >
                        {sourceOfSuppliers.map(supplier =>
                          supplier ? (
                            <MuiMenuItem
                              onClick={() => handleCloseSupplier(supplier)}
                              key={supplier?.sourceOfSupplyNumber}
                              value={supplier?.sourceOfSupplyNumber}
                            >
                              <ListItemText>{`${supplier.sourceOfSupplyName} (${supplier.sourceOfSupplyNumber})`}</ListItemText>
                            </MuiMenuItem>
                          ) : (
                            <></>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <></>
                )}

                <Box sx={{ my: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ width: "230px" }}
                    onClick={async e => {
                      e.preventDefault();
                      const path = hasOrder ? `/order/edit/${currentOrder.id}/` : "/roster";

                      await navigate(path);
                    }}
                  >
                    {hasOrder ? "Resume Order" : "New Order"}
                  </Button>
                </Box>
              </Box>
            )}
          </AnimatePresence>
          <motion.div layout {...collapsedItem}>
            {!collapse && !dense && (
              <motion.div>
                <Divider textAlign="left" sx={{ fontSize: "12px", color: "rgba(114, 114, 114, 0.34)" }}>
                  Ordering
                </Divider>
              </motion.div>
            )}
            <List dense={dense}>
              {orderMenuItems.map(itemProps => {
                const { text, icon, items } = itemProps;
                const isListMenuItem = !!items?.length;
                return isListMenuItem ? <MenuList title={text} icon={icon} items={items} /> : <MenuItem key={`item-order-${text}`} {...itemProps} />;
              })}
            </List>
          </motion.div>

          <Box>
            <Divider textAlign="left" sx={{ fontSize: "12px", color: theme.palette.grey[600] }}>
              Support
            </Divider>
            <List>
              {helpMenuItems.map(itemProps => (
                <MenuItem key={`item-help-${itemProps.text}`} {...itemProps} />
              ))}
              <Logout></Logout>
            </List>
          </Box>
        </Stack>
      </Box>
    </NoSsr>
  );
};

export default Menu;
