import SidebarContext from "@context/SidebarContext";
import { useMediaQuery } from "@mantine/hooks";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import React, { FC, MouseEventHandler, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TMenuItem } from "./config";
import { ListItemButton } from "@mui/material";

const isExternal = (path: string) => path.startsWith("http");

export type MenuItemProps = TMenuItem & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listItemProps?: any;
};

const MenuItem: FC<MenuItemProps> = ({ path, text, icon, listItemProps, hide }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isNotMobile = useMediaQuery("( min-width:  767px )");
  const { setSidebar } = useContext(SidebarContext);
  const isCurrent = pathname === encodeURI(path);
  const isAncestor = pathname.startsWith(path) && !isCurrent;
  const external = isExternal(path);

  const clickHandler = () => {
    if (external) {
      return window.open(`${path}`, "_blank", "noopener");
    }

    if (isNotMobile) {
      navigate(path);
    } else {
      setSidebar(false);
      setTimeout(() => navigate(path), 150);
    }
  };

  return (
    <ListItemButton
      onClick={clickHandler as MouseEventHandler<HTMLButtonElement>}
      selected={isCurrent || isAncestor}
      {...listItemProps}
      style={hide ? { display: "none" } : {}}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text.replace(/Produce\s+-\s+/, "")} />
    </ListItemButton>
  );
};

export default MenuItem;
